interface SwipeData {
  card: string;
  swipe: "like" | "dislike";
  rightAnswer?: boolean;
}

export class PlayerData {
  swipes: SwipeData[] = [];
  score: number = 0;
  uitslag: string = "";
}
