import { SwipeGame } from "./Game";

export interface ResultConfig {
  min: number;
  max: number;
  title?: string;
  image: string;
  text?: string;
}

export class Result {
  config!: ResultConfig;
  game: SwipeGame;

  card!: HTMLDivElement;

  constructor(game: SwipeGame, score: number, configs: ResultConfig[]) {
    this.game = game;

    if (configs.length === 0) {
      return;
    }

    if (configs.length > 0) this.config = configs[0];

    if (this.game.config.randomResult) {
      const random = Math.round(Math.random() * (configs.length - 1));

      this.config = configs[random];
    } else {
      for (let i = 0; i < configs.length; i++) {
        const u = configs[i];

        if (score >= u.min && score <= u.max) {
          this.config = u;
        }
      }
    }

    if (!this.config) {
      return;
    }

    // this.createCardElement();
  }

  toString() {
    let string = "";
    if (this.config.title) {
      string += this.config.title;
    }
    if (this.config.text) {
      if (string !== "") {
        string += " - ";
      }
      string += this.config.text;
    }
    return string;
  }
}
