import { Card, CardConfig } from "./Card";
import { Platform } from "./Platform";
import { PlayerData } from "./PlayerData";
import { Result, ResultConfig } from "./Uitslag";
import { shuffle } from "./Utility";

export interface GameConfig {
  path: string;
  cards: CardConfig[];
  question: string;
  results: ResultConfig[];
  shuffleCards: boolean;
  showCardTitle: boolean;
  lives?: number;
  timeLimit?: number;
  showQuestion: boolean;
  cardLimit?: number;
  randomResult: boolean;
  resultPath: string;
  font: string;
  fontSize: number;
  fontColor: string;
  swipeSound?: string;
  rightAnswerSound?: string;
  wrongAnswerSound?: string;

  cardContainerColor: string;
  cardBorderWidth: string;
  cardBorderColor: string;
  cardBorderRadius: string;
  cardColor: string;

  indicatorPadding: number;
  indicatorWidth: number;
  swipeLeftIndicatorImage?: string;
  swipeRightIndicatorImage?: string;
}

export class SwipeGame {
  firstGame: boolean = true;
  platform: Platform;
  config: GameConfig;
  playing: boolean = false;

  gameScreen!: HTMLDivElement;

  cardContainer!: HTMLDivElement;
  questionElement!: HTMLDivElement;

  playerData: PlayerData = new PlayerData();
  cards: Card[] = [];
  lives?: number;

  timeLeft?: number;

  currentCard: number = 0;

  timeBarInterval: any;

  constructor(config: GameConfig) {
    this.platform = new Platform();
    this.config = config;
    this.init();
  }

  getElement(name: string) {
    return document.getElementById(name) as HTMLDivElement;
  }

  init() {
    document.body.style.fontSize = this.config.fontSize + "px";
    document.body.style.color = this.config.fontColor;
    this.gameScreen = this.getElement("game-screen");

    this.cardContainer = this.getElement("card-container");

    this.cardContainer.style.backgroundColor = this.config.cardContainerColor;

    this.cardContainer.style.borderRadius = this.config.cardBorderRadius + "px";

    this.questionElement = this.getElement("question");

    if (!this.config.showQuestion) {
      this.questionElement.remove();
    }

    this.restart();

    this.platform.init(this, this.config);
    this.platform.preloadSounds(this.config);
  }

  setQuestion() {
    if (!this.config.showQuestion) {
      return;
    }
    const index = this.getCurrentIndex();
    if (index < 0) {
      return;
    }
    const card = this.cards[index];
    let question = this.config.question;
    if (card && card.config.question) {
      question = card.config.question;
    }
    this.questionElement.innerHTML = `<div>${question.replaceAll(
      "\n",
      "<br/>"
    )}</div>`;
  }

  getCurrentIndex() {
    return this.cards.length - this.currentCard - 1;
  }

  onSwipe() {
    this.platform.playSound(this.config.swipeSound);

    this.currentCard += 1;

    this.setProgressBarWidth();

    this.setQuestion();

    if (this.currentCard >= this.cards.length) {
      this.goToResult();
    }
  }

  play() {
    if (this.playing) {
      return;
    }
    this.playing = true;
    if (this.timeLeft) {
      this.timeBarInterval = setInterval(() => {
        this.timePassed(50);
      }, 50);
    }
    this.platform.gamestarted();
  }

  loseLife() {
    this.platform.playSound(this.config.wrongAnswerSound);
    if (!this.lives) return;

    this.lives -= 1;

    this.platform.sendLives(this.lives);

    if (this.lives === 0) {
      this.goToResult();
    }
  }

  goToResult() {
    this.firstGame = false;
    this.playing = false;

    clearInterval(this.timeBarInterval);

    if (this.config.results.length === 0) {
      this.platform.gameover(
        this.playerData.score,
        "",
        JSON.stringify(this.playerData)
      );
      return;
    }

    const result = new Result(this, this.playerData.score, this.config.results);

    this.playerData.uitslag = result.toString();

    // this.playerData.uitslag = result.config

    this.platform.gameover(
      this.playerData.score,
      result.config,
      JSON.stringify(this.playerData)
    );
  }

  questionHeight: number = 0;

  restart() {
    this.questionHeight = 0;
    this.cards.forEach((card) => {
      card.destroy();
    });
    this.cards = [];

    this.playerData = new PlayerData();

    this.lives = this.config.lives;

    if (this.lives) this.platform.sendLives(this.lives);

    this.timeLeft = this.config.timeLimit;

    if (this.config.shuffleCards) {
      this.config.cards = shuffle(this.config.cards);
    }

    this.toggleVisible(this.gameScreen, true);

    if (this.config.showQuestion) {
      this.questionHeight = Math.max(
        this.questionHeight,
        this.createQuestionElement(this.config.question)
      );
    }

    for (let i = 0; i < this.config.cards.length; i++) {
      if (this.config.cardLimit && i >= this.config.cardLimit) {
        break;
      }
      const card = this.config.cards[i];

      this.cards.push(new Card(this, card, () => this.onSwipe(), i));

      if (this.config.showQuestion)
        this.questionHeight = Math.max(
          this.questionHeight,
          this.createQuestionElement(card.question)
        );
    }

    this.questionElement.style.height = `${this.questionHeight}px`;

    this.currentCard = 0;

    this.setProgressBarWidth();

    this.setQuestion();

    this.platform.ready();
  }

  createQuestionElement(question?: string) {
    if (!question) {
      return 0;
    }

    const questionElement = document.createElement("div");

    questionElement.className = "text-center opacity-0 w-full absolute";

    questionElement.innerHTML = question.replaceAll("\n", "<br/>");

    this.questionElement.appendChild(questionElement);

    return questionElement.clientHeight;
  }

  setProgressBarWidth() {
    const width = Math.round(
      ((this.currentCard + 1) / (this.cards.length + 1)) * 100
    );
    this.platform.sendProgress(width);
  }

  timePassed(time: number) {
    if (!this.timeLeft || !this.config.timeLimit) {
      return;
    }
    this.timeLeft = this.timeLeft - time;
    this.platform.sendTimeleft((this.timeLeft / this.config.timeLimit) * 100);

    if (this.timeLeft <= 0) {
      this.goToResult();
    }

    // this.timeBarElement.style.width = `${
    // 	(this.timeLeft / this.config.timeLimit) * 100
    // }%`;
  }

  toggleVisible(element: HTMLDivElement, visible: boolean) {
    if (!element) {
      return;
    }
    element.style.opacity = visible ? "100%" : "0";
    element.style.pointerEvents = visible ? "all" : "none";
  }

  addPoints(points: number) {
    this.platform.playSound(this.config.rightAnswerSound);

    this.playerData.score += points;

    this.platform.sendScore(this.playerData.score);
    // this.scoreElement.innerHTML = this.playerData.score
    // 	.toString()
    // 	.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
