import { Configurator, DEFAULT_SETTINGS } from "./Configurator";

const customConfig = getSettingsFromURI(
  location.search
) as typeof DEFAULT_SETTINGS;
let config = DEFAULT_SETTINGS;

if (customConfig)
  for (const [key, value] of Object.entries(customConfig)) {
    (config as any)[key] = value;
  }

const configurator = new Configurator();
configurator.init(config);

function getSettingsFromURI(uri: string) {
  let settingString;
  const split = uri.split("&");
  for (let s of split) {
    if (s[0] === "s") {
      settingString = decodeURIComponent(escape(atob(s.split("=")[1])));
    }
  }


  if (settingString === undefined) {
    return null;
  }

  let jsonString = "{";
  let settings: string[] = settingString.split("\n");

  settings.forEach((s) => {
    const keyAndValue = s.split("=");
    if (keyAndValue.length === 1) {
      return;
    }

    const key = keyAndValue[0].trim();
    const value = keyAndValue[1].trim();

    if (value === "default") {
      return;
    }

    let line = '"' + key.toString() + '": ';

    if (value.includes("[") && value.includes("]")) {
      line += value.toString();
    } else {
      line += '"' + value.toString() + '"';
    }

    if (settings.indexOf(s) < settings.length - 1) {
      line += ",";
    }

    jsonString += line;
  });

  jsonString += "}";

  return JSON.parse(jsonString);
}
